html {
  background-color: #0F1118;
}
.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
#legalContainer {
  background-color: white;
  width: 100vw;
}
#legal {
  max-width: 50%;
  padding: 10px;
}
.mainContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.background {
  position: absolute;
}
.lottieBackground {
  z-index: 1;
  height: 100vh;
}

.section {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.sectionPhone {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.screenshot {
  max-height: 80vh;
  z-index: 2;
}
.wordsContent {
  z-index: 2;
  margin: 1em;
  margin-top: 5em;
}
.logo {
  width: 120px;
  margin-bottom: 1em;
}
.heroText {
  font-style: normal;
  font-weight: 600;
  font-size: 4em;
  line-height: 0.8em;
  margin: 0.25em 0px;
  z-index: 2;
}
.heroSubmittedText {
  font-style: normal;
  font-weight: 600;
  font-size: 2.8em;
  line-height: 1.2em;
  margin: 0.25em 0px;
  z-index: 2;
}
.subtitleText {
  font-style: normal;
  font-weight: 400;
  font-size: 2em;
  z-index: 2;
}
.inputContainer {
  width: 100%;
  margin-top: 12px;
  display: flex;
}
.emailInput {
  flex: 1;
  margin-right: 16px;
  padding: 19px 26px;

  background: #595D6F;
  border-radius: 10px;

  flex: none;
  flex-grow: 1;

  color: #9FA4BC;

  outline: none;
  border: none;
  z-index: 2;
  font-size: 1.5em;
}

.emailInput::placeholder {
  color: #9FA4BC;
}

.emailInput:focus-visible {
  background-color: white;
  color: #0D0F14;
  font-weight: 600;
}

.emailButton {
  border: none;
  border-radius: 10px;
  padding: 19px 26px;
  cursor: pointer;
  z-index: 2;
  font-size: 1.5em;
}

.disclosureText {
  font-size: 0.9em;
  z-index: 2;
}

.link {
  text-decoration: none;
  z-index: 2;
}

.footer {
  padding: 80px;
  color: white;
}

.footer .link {
  margin: 16px;
}

@media (min-width: 950px) {
  .mainContent {
    height: 100vh;
  }
  .section {
    padding: 80px;
  }
}
@media (max-width: 950px) {
  .mainContent {
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
  }
}
@media (max-width: 650px) {
  .heroText {
    font-size: 3em;
  }
  .subtitleText {
    font-size: 2em;
  }
  .inputContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
  .emailButton {
    font-size: 1em;
    margin-top: 1em;
  }
  .emailInput {
    font-size: 1em;
    margin: 0;
  }
}
@media (max-width: 400px) {
  .heroText {
    font-size: 2em;
  }
  .subtitleText {
    font-size: 1em;
  }

  .emailButton {
    font-size: 1em;
  }

}
